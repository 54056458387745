<template>
  <topbar
    :titulo="$t('ACADEMICO.BOLETIM')"
    :subtitulo="$t('ACADEMICO.BOLETIM_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  />
</template>
  <script>
// Utils & Aux:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO.BOLETIM') },
      ],
    };
  },
};
</script>
  