<template>
  <div>
    <b-table
      :fields="tabela.fields"
      :items="form"
      class="mt-3 position"
      show-empty
      sticky-header="400px"
      responsive="xl"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      striped
    >
      <template #cell(nota)="item">
        <template>
          <div>
            <b-input-group class="mt-3">
              <div class="col-xl-12 flex">
                <input-number
                  class="mr-2 mb-2"
                  ref="nota"
                  v-model="item.item.nota"
                  :min="0"
                  :max="10"
                  :maxLength="10"
                  size="sm"
                />
              </div>
            </b-input-group>
          </div>
        </template>
      </template>
      <template #cell(faltasValidas)="item">
        <template>
          <div>
            <b-input-group class="mt-3">
              <div class="col-xl-12 flex">
                <input-number
                  class="mr-2 mb-2"
                  ref="faltasValidas"
                  v-model="item.item.faltasValidas"
                  :min="0"
                  :max="100"
                  :maxLength="100"
                  size="sm"
                />
              </div>
            </b-input-group>
          </div>
        </template>
      </template>
    </b-table>
  </div>
</template>
<script>
import { InputNumber } from '@/components/inputs';

export default {
  components: {
    InputNumber,
  },
  name: 'TabelaBoletim',
  props: {
    form: {
      type: Array,
      default: Array,
    },
  },
  watch: {
    form: {
      handler() {
        this.$emit('atualizado', this.form);
      },
      deep: true,
    },
  },
  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'disciplina.descricao',
            label: 'Disciplina',
            sortable: true,
          },
          {
            key: 'boletim.media',
            label: 'Média final',
            sortable: true,
          },
          {
            key: 'boletim.totalFaltas',
            label: 'Faltas totais',
            sortable: true,
          },
          {
            key: 'nota',
            label: 'Média',
            sortable: true,
          },
          {
            key: 'faltasValidas',
            label: 'Faltas',
            sortable: true,
          },
        ],
      },
      editar: '',
      editarFalta: '',
    };
  },
  methods: {
    habilitarEdicao(index) {
      this.editar = index;
    },
    habilitarEdicaoFalta(index) {
      this.editarFalta = index;
    },
  },
};
</script>

<style>
.position thead {
  position: relative;
  z-index: 300;
}
.position th {
  width: 800px;
  padding: 10px;
}
.position th div {
  text-align: left;
  padding-left: 10px;
}
.position td {
  width: 800px;
  padding: 0px 5px;
}

td .input-group {
  height: auto !important;
  margin: 0px !important;
}

table td,
table th,
.table td,
.table th {
  padding: 0px 12px;
}

.position td {
  max-width: 90px;
}

.btnBoletim {
  height: 35px;
  position: absolute;
  z-index: -300;
  margin-top: 7px;
}

.flex {
  display: flex;
}
</style>
