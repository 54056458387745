<template>
  <div>
    <modal
      id="modalBoletim"
      :tamanho="'xl'"
      :exibir="exibir"
      @fechar="fecharModal"
      :titulo="titulo"
      :campos="$refs"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-12">
            <span
              >*As faltas apresentadas aqui são os totais de faltas válidas
              conforme lançamento na tela de frequência</span
            >
            <tabela-boletim
              @atualizado="atualizarBoletim"
              :form="boletimDados"
            />
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-between">
          <b-button variant="secondary" @click="fecharModal">
            {{ $t('GERAL.FECHAR') }}
          </b-button>
          <b-button class="ml-2" variant="primary" @click="salvar">
            {{ $t('GERAL.SALVAR') }}
          </b-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import BoletimService from '@/common/services/boletim/boletim.service';

import Modal from '@/components/modal/Modal.vue';
import TabelaBoletim from './TabelaBoletim';
export default {
  name: 'ModalBoletim',
  components: {
    Modal,
    TabelaBoletim,
  },
  props: {
    form: { type: Array, default: Array },
    exibir: { type: Boolean, default: true },
  },
  watch: {
    exibir: 'obterBoletimPorFiltro',
  },
  data() {
    return {
      boletimDados: [],
      boletinsAtualizados: [],
    };
  },
  computed: {
    titulo() {
      const nome =
        this.form[0]?.nomeAluno === undefined ? '' : this.form[0].nomeAluno;
      return this.$t('Boletim: ' + `${nome}`);
    },
  },
  methods: {
    obterBoletimPorFiltro() {
      this.$store.dispatch(START_LOADING);
      BoletimService.obterBoletinsPorFiltro(this.form[0])
        .then(({ data }) => {
          this.boletimDados = data;
          this.boletimDados?.forEach((item) => {
            if (item.boletim) {
              item.boletim.media = item.boletim.media
                ? item.boletim.media.toFixed(1)
                : item.boletim.media;
            }
          });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    atualizarBoletim(atualizados) {
      this.boletinsAtualizados = atualizados;
    },

    adicionarAnoLetivoId() {
      const boletinsComAnoLetivoId = this.boletinsAtualizados.map((item) => {
        return {
          ...item,
          anoLetivoId: this.form[0].anoLetivoId,
        };
      });

      return boletinsComAnoLetivoId;
    },

    salvar() {
      const boletinsAtualizados = this.adicionarAnoLetivoId();

      const dadosNegativos = boletinsAtualizados
        .map((item) => item.faltasValidas < 0 || item.nota < 0)
        .includes(true);
      if (dadosNegativos) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          'Não é possível enviar faltas ou notas negativas'
        );
        return;
      }
      this.$store.dispatch(START_LOADING);
      BoletimService.criarListaBoletins(boletinsAtualizados)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), 'Boletins atualizados');
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
