import ApiService from '@/common/api/api.service';

const BoletimService = {
  async obterBoletinsPorFiltro(form) {
    let { data } = await ApiService.get(`BoletimSegmentacao/obter-por-filtro`, {
      params: {
        SegmentacaoId: form.segmentacaoId,
        TurmaId: form.turmaId,
        MatriculaId: form.matriculaId,
        AnoLetivoId: form.anoLetivoId,
      },
    });
    return data;
  },

  async criarListaBoletins(form) {
    let { data } = await ApiService.post(
      `BoletimSegmentacao/criar-lista`,
      form
    );
    return data;
  },

  async imprimirBoletimNotas(form) {
    let { data } = await ApiService.get(
      `Boletim/imprimir-relatorio-boletim-notas-parciais`,
      {
        params: {
          TurmaId: form.turmaId,
          MatriculaId: form.matriculaId,
          AnoLetivoId: form.anoLetivoId,
        },
      }
    );
    return data;
  },
  
  async imprimirBoletins(form) {
    let { data } = await ApiService.get(`Boletim/imprimir-relatorio-boletins`, {
      params: {
        TurmaId: form.turmaId,
        AnoLetivoId: form.anoLetivoId,
      },
    });
    return data;
  },
};

export default BoletimService;
