<template>
  <div class="mb-4">
    <titulo-boletim />
    <filtro-boletim @filtrar="filtrar" @limpar-filtro="limparFiltro" />
    <tabela :items="resultadoBusca" />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import PessoaService from '@/common/services/pessoa/pessoa.service';

// Components:
import FiltroBoletim from './components/Filtro';
import Tabela from './components/Tabela';
import TituloBoletim from '@/views/academico/boletins/components/TituloBoletim';

export default {
  components: {
    TituloBoletim,
    FiltroBoletim,
    Tabela,
  },
  data() {
    return {
      resultadoBusca: [],
    };
  },
  methods: {
    filtrar(filtroUtilizado) {
      this.$store.dispatch(START_LOADING);
      PessoaService.obterAlunosPorFiltro(filtroUtilizado)
        .then(({ data }) => {
          this.resultadoBusca = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limparFiltro() {
      this.resultadoBusca = [];
    },
  },
};
</script>
