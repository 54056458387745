<template>
  <div>
    <b-table
      :fields="tabela.fields"
      :items="items"
      responsive="sm"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      class="mt-3"
      selectable
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <b-button variant="primary" @click="processarBoletim(item)">
          {{ $t('ACADEMICO.PROCESSAR_BOLETIM') }}
        </b-button>
      </template>
    </b-table>
    <modal-boletim
      :exibir="exibir"
      @fechar="fecharModal"
      :form="alunoSelecionado"
    />
  </div>
</template>
<script>
// import mensagem from '@/common/utils/mensagem';
import ModalBoletim from './ModalBoletim.vue';

export default {
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },
  components: { ModalBoletim },
  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'codigoMatricula',
            label: this.$t('ACADEMICO.MATRICULA'),
            sortable: true,
          },
          {
            key: 'nomeAluno',
            label: this.$t('GERAL.NOME'),
            sortable: true,
          },
          {
            key: 'acoes',
            label: this.$t('GERAL.ACOES'),
          },
        ],
      },
      exibir: false,
      alunoSelecionado: [],
    };
  },
  methods: {
    processarBoletim(item) {
      this.alunoSelecionado = [{ ...item.item }];
      this.exibir = true;
    },
    fecharModal() {
      this.exibir = false;
    },
  },
};
</script>
